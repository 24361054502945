import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import styled from '@emotion/styled';

const AllYearsContent = styled("div")`
  padding: 3rem 4rem;
  background-color: #201D1D;
  color: #f9d83d;
  @media screen and (max-width: 550px) {
    padding: 2rem 1.5rem;
  }

  @media screen and (min-width: 1100px) {
    max-width: calc(100% / 3);
    flex-basis: calc(100% / 3);
  }

  h2 {
    color: #f9d83d;
    margin-bottom: 1em;
    font-size: 1.8rem;
    @media screen and (max-width: 550px) {
      font-size: 1.5rem;
      margin-bottom: .5em;
    }
  }
`;

const AllYearsList = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const AllYearsItem = styled("li")`
  margin-right: 1rem;
  margin-bottom: .5rem;
  a {
    color: #f9d83d;
    &:hover {
      text-decoration: none;
    }
  }
`;

class AllYears extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <AllYearsContent>
        <h2>Všechny ročníky</h2>
        <AllYearsList>
          {posts &&
            posts.map(({ node: post }) => (
              <AllYearsItem key={post.id}>
                <Link to={post.fields.slug}>{post.frontmatter.date}</Link>
              </AllYearsItem>
            ))}
        </AllYearsList>
      </AllYearsContent>
    );
  }
}

AllYears.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query AllYearsQuery {
        allMarkdownRemark(
          limit: 1000
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <AllYears data={data} count={count} />}
  />
);
