import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const Article = styled("article")`
  padding: 2.5rem 3rem;
  background-color: #ffffff;
  box-shadow: 0 40px 60px 0 rgba(0,0,0,0.20);
  @media screen and (max-width: 550px) {
    padding: 1.5rem;
  }

  @media screen and (min-width: 1100px) {
    max-width: calc((100% / 3) * 2);
    flex-basis: calc((100% / 3) * 2);
  }

  h1 a {
    text-decoration: none;
  }
  
  p {
    font-size: 1.1rem;
    @media screen and (max-width: 550px) {
      font-size: .9rem;
    }
  }
`;

class LastYear extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Article>
        {posts &&
          posts.map(({ node: post }) => (
            <div key={post.id}>
              <h1>
                <Link to={post.fields.slug}>
                  {post.frontmatter.title}
                </Link>
              </h1>
              <p>{post.frontmatter.description}</p>
              <Link
                className={css`
                  font-size: 1.1875rem;
                  font-weight: 700;
                  @media screen and (max-width: 550px) {
                    font-size: 1rem;
                  }
                `}
                to={post.fields.slug}
              >
                Celá tisková zpráva
              </Link>
            </div>
          ))}
      </Article>
    );
  }
}

LastYear.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query LastYearQuery {
        allMarkdownRemark(
          limit: 1
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <LastYear data={data} count={count} />}
  />
);
