import React from "react";
import styled from '@emotion/styled';

import Header from "../../components/Header";
import LastYear from "../../components/LastYear";
import AllYears from "../../components/AllYears";
import Container from "../../components/Container";
import InfoSection from "../../components/InfoSection";

import backgroundPortable from "../../img/bg-header-small.jpg"
import backgroundNotebook from "../../img/bg-header-notebook.jpg"
import backgroundFullHD from "../../img/bg-header-fullhd.jpg"

const HeaderWrapper = styled("div")`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url(${backgroundPortable});
  background-size: cover;
  background-position: center center;
  @media screen and (min-width: 1024px) {
    background-image: url(${backgroundNotebook});
  }
  @media screen and (min-width: 1440px) {
    background-image: url(${backgroundFullHD});
  }
  @media screen and (max-width: 550px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 550px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const HeaderArticles = styled("div")`
  margin-top: 5rem;
  @media screen and (max-width: 550px) {
    margin-top: 2rem;
  }
`;

const ArticleWrapper = styled("div")`
  @media screen and (min-width: 1100px) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <>
        <HeaderWrapper>
          <Header />
          <HeaderArticles>
            <Container>
              <ArticleWrapper>
                <LastYear />
                <AllYears />
              </ArticleWrapper>
            </Container>
          </HeaderArticles>
        </HeaderWrapper>
        <InfoSection />
      </>
    );
  }
}
